import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  useToast,
  PopoverTrigger,
  Text,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  Avatar,
  VStack,
  HStack,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { UserState } from "../../../Context/UserProvider";
import { AuthState } from "../../../Context/AuthProvider";
import { Check } from "lucide-react";

const AssigneeDropDown = ({
  onOpen: externalOnOpen,
  isOpen: externalIsOpen,
  onClose: externalOnClose,
  assigneeId,
  setAssigneeId,
  projectId,
  reviewerId,
  recommendedAssignees,
  isLoadingRecommendations,
  clearRecommendations = () => {},
  forUpdation = false,
  isTriggerBgWhite = false,
  initialValue,
  value,
}) => {
  const {
    isOpen: internalIsOpen,
    onOpen: internalOnOpen,
    onClose: internalOnClose,
  } = useDisclosure();

  const isOpen = externalIsOpen ?? internalIsOpen;
  const onOpen = externalOnOpen ?? internalOnOpen;
  const onClose = externalOnClose ?? internalOnClose;

  const { organizationMembers, selectedOrganization, updateTaskAssignee } =
    UserState();
  const { user } = AuthState();

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef(null);
  const listRef = useRef(null);
  const [assignee, setAssignee] = useState(null);
  const [assigneeName, setAssigneeName] = useState("");
  const [projectMembers, setProjectMembers] = useState([]);
  const toast = useToast();

  const changeAssignee = async (assigneeId) => {
    if (forUpdation) {
      await updateTaskAssignee(assigneeId);
    }
  };

  const handleSelect = (selectedUser) => {
    setAssigneeId(
      selectedUser.firebaseUserId,
      (
        selectedUser.name ||
        `${selectedUser.firstName} ${selectedUser.lastName}`
      ).toUpperCase()
    );
    setQuery(
      (selectedUser?.name
        ? selectedUser.name
        : `${selectedUser.firstName} ${selectedUser.lastName}`
      ).toUpperCase()
    );
    setAssignee(selectedUser);
    setAssigneeName(
      (selectedUser?.name
        ? selectedUser.name
        : `${selectedUser.firstName} ${selectedUser.lastName}`
      ).toUpperCase()
    );
    onClose();
    changeAssignee(selectedUser.firebaseUserId);
  };

  const handleKeyDown = (e) => {
    if (!isOpen) {
      if (e.key === "Enter" || e.key === "ArrowDown") {
        e.preventDefault();
        onOpen();
        return;
      }
    }

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setSelectedIndex((prev) =>
          prev <
          (recommendedAssignees?.length > 0
            ? recommendedAssignees.length - 1
            : filteredUsers.length - 1)
            ? prev + 1
            : prev
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev));
        break;
      case "Enter":
        e.preventDefault();
        if (recommendedAssignees?.length > 0) {
          if (selectedIndex >= 0 && recommendedAssignees[selectedIndex]) {
            handleSelect(recommendedAssignees[selectedIndex]);
          }
        } else {
          if (selectedIndex >= 0 && filteredUsers[selectedIndex]) {
            handleSelect(filteredUsers[selectedIndex]);
          }
        }
        break;
      case "Escape":
        e.preventDefault();
        onClose();
        break;
      case "Tab":
        onClose();
        break;
      default:
        break;
    }
  };

  const filterUsers = () => {
    if (!projectId) {
      toast({
        title: "You need to select a project first.",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
      setQuery("");
      return;
    }

    const words = query.toLowerCase().split(" ");
    const filtered = projectMembers.filter(
      (member) =>
        member.firebaseUserId !== reviewerId &&
        words.every((word) =>
          `${member.firstName} ${member.lastName} ${member.designation}`
            .toLowerCase()
            .includes(word)
        )
    );

    setFilteredUsers(filtered);
    setSelectedIndex(filtered.length > 0 ? 0 : -1);
  };

  useEffect(() => {
    if (!selectedOrganization.isPersonal) {
      if (assigneeId && !assignee) {
        const foundAssignee = organizationMembers?.find(
          (member) => member.firebaseUserId === assigneeId
        );
        if (foundAssignee) {
          setAssigneeName(
            `${foundAssignee.firstName} ${foundAssignee.lastName}`
          );
          setAssignee(foundAssignee);
        }
      }

      if (isOpen) {
        if (query === "") {
          setFilteredUsers(projectMembers?.slice(0, 4) || []);
        } else {
          filterUsers();
        }
      }
    }
  }, [query, isOpen, assigneeId, organizationMembers]);

  useEffect(() => {
    if (initialValue) {
      setAssigneeName(initialValue.toUpperCase());
      setQuery(initialValue.toUpperCase());
    }
  }, [initialValue]);

  useEffect(() => {
    if (value !== undefined) {
      setAssigneeName(value.toUpperCase());
      setQuery(value.toUpperCase());
    }
  }, [value]);
  useEffect(() => {
    if (selectedOrganization.isPersonal) {
      setAssigneeId(
        user?.firebaseUserId,
        `${user?.firstName} ${user?.lastName}`
      );
      setAssigneeName(`${user?.firstName} ${user?.lastName}`);
      setAssignee(user);
      return;
    }

    if (organizationMembers && projectId) {
      const members = organizationMembers.filter(
        (member) =>
          member?.projectIds?.includes(projectId) ||
          member?.projectId === projectId
      );
      setProjectMembers(members);

      if (isOpen && query === "") {
        setFilteredUsers(members.slice(0, 4));
      }
    }
  }, [projectId, organizationMembers, isOpen]);

  useEffect(() => {
    if (selectedIndex >= 0 && listRef.current) {
      const element = listRef.current.children[selectedIndex];
      if (element) {
        element.scrollIntoView({
          block: "nearest",
          behavior: "smooth",
        });
      }
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (initialValue) {
      setAssigneeName(initialValue);
    }
  }, [initialValue]);

  const UserItem = ({ user: member, isSelected }) => (
    <HStack
      px={4}
      py={2}
      bg={isSelected ? "blue.50" : "white"}
      _hover={{ bg: "gray.50" }}
      cursor="pointer"
      onClick={() => handleSelect(member)}
    >
      <Avatar
        size="sm"
        name={
          member?.name
            ? member.name.toUpperCase()
            : `${member.firstName.toUpperCase()} ${member.lastName.toUpperCase()}`
        }
        src={member.imageUrl}
      />
      <VStack spacing={0} align="start" flex={1}>
        <Text fontWeight="medium">
          {member?.name
            ? member.name.toUpperCase()
            : `${member.firstName.toUpperCase()} ${member.lastName.toUpperCase()}`}
        </Text>
        <Text fontSize="sm" color="gray.500">
          {member.designation}
        </Text>
        <Text fontSize="sm" color="gray.500">
          Current Bandwidth: {member.currentBandwidth || "N/A"}
        </Text>
      </VStack>
      {member.firebaseUserId === assigneeId && (
        <Check size={16} className="text-blue-500" />
      )}
    </HStack>
  );

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      initialFocusRef={inputRef}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <Button
          w="full"
          bg={isTriggerBgWhite ? "white" : "gray.100"}
          _hover={{ opacity: 0.8 }}
          p={0}
        >
          <InputGroup w="full">
            {assigneeName && !isOpen && (
              <InputLeftElement>
                <Avatar
                  name={assigneeName}
                  size="xs"
                  src={assignee?.imageUrl}
                />
              </InputLeftElement>
            )}
            <Input
              ref={inputRef}
              w="full"
              isDisabled={!projectId}
              value={
                isOpen
                  ? query.toUpperCase()
                  : value || assigneeName.toUpperCase()
              }
              placeholder={!projectId ? "Select a project first" : "Assignee"}
              color={selectedOrganization.isPersonal ? "blue.500" : "black"}
              fontWeight={
                selectedOrganization.isPersonal ? "semibold" : "normal"
              }
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={handleKeyDown}
              onClick={() => !isOpen && onOpen()}
            />
          </InputGroup>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        w="300px"
        border="1px solid"
        borderColor="gray.200"
        boxShadow="lg"
      >
        <PopoverBody p={0}>
          {recommendedAssignees?.length > 0 && (
            <Flex
              justifyContent={"flex-end"}
              color="blue.500"
              onClick={clearRecommendations}
              textDecor={"underline"}
              cursor={"pointer"}
              fontSize={"sm"}
              m={1}
            >
              <Button size={"sm"} style={{ all: "unset" }}>
                Clear
              </Button>
            </Flex>
          )}
          <VStack
            ref={listRef}
            spacing={0}
            maxH="250px"
            overflowY="auto"
            align="stretch"
          >
            {isLoadingRecommendations ? (
              <Flex
                height={"100px"}
                alignItems={"center"}
                justifyContent={"center"}
                w={"full"}
              >
                <Spinner size={"sm"} />
              </Flex>
            ) : filteredUsers.length > 0 ? (
              (recommendedAssignees?.length > 0
                ? recommendedAssignees
                : filteredUsers
              ).map((member, index) => (
                <UserItem
                  key={member.firebaseUserId}
                  user={member}
                  isSelected={index === selectedIndex}
                />
              ))
            ) : (
              <Text p={4} color="gray.500">
                {query ? "No matching assignees found" : "Enter assignee name"}
              </Text>
            )}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default AssigneeDropDown;

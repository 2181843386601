import { useEffect, useState } from "react";
import axios from "axios";
import { NOTIFICATION_BASE_URL as baseUrl } from "../baseurls";
import { AuthState } from "../Context/AuthProvider";
import { UserState } from "../Context/UserProvider";

const useNotifications = () => {
  const { accessToken, user } = AuthState();
  const { selectedOrganization } = UserState();
  const [notifications, setNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [filter, setFilter] = useState("unread");

  const sendNotification = async (recipientFirebaseId, type, title, message) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization?.id,
        },
      };
      await axios.post(
        `${baseUrl}/notifications`,
        { recipientFirebaseId, type, title, message },
        config
      );
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };
  
  const fetchNotifications = async (pageNum = 1) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization?.id,
        },
      };

      setIsLoading(true);
      const response = await axios.get(
        `${baseUrl}/notifications?page=${pageNum}&unread_only=false`,
        config
      );
      const newNotifications = response?.data?.notifications || [];
      
      setNotifications(prev => {
        const updatedNotifications = pageNum === 1 ? newNotifications : [...prev, ...newNotifications];
        return updatedNotifications.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      });
      
      setHasMore(response.data.has_next);
      setPage(pageNum);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadLatestNotifications = () => {
    setNotifications([]);
    fetchNotifications(1);
  };

  const handleLoadMore = () => {
    if (!isLoading && hasMore) {
      fetchNotifications(page + 1);
    }
  };

  const markAllNotificationsAsRead = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization?.id,
        },
      };
      await axios.put(`${baseUrl}/notifications/mark-all-read`, {}, config);

      setNotifications((prev) =>
        prev.map((notification) => ({ ...notification, read: true }))
      );
      setUnreadCount(0);
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  const markNotificationAsRead = async (notificationId) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization?.id,
        },
      };
      await axios.put(
        `${baseUrl}/notifications/${notificationId}/read`,
        {},
        config
      );

      setNotifications((prev) =>
        prev.map((notification) =>
          notification._id === notificationId
            ? { ...notification, read: true }
            : notification
        )
      );

      setUnreadCount((prev) => Math.max(prev - 1, 0));
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const fetchUnreadCount = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization?.id,
        },
      };
      const response = await axios.get(
        `${baseUrl}/notifications/unread-count`,
        config
      );
      setUnreadCount(response.data.unread_count);
    } catch (error) {
      console.error("Error fetching unread count:", error);
    }
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setPage(1);
    fetchNotifications(1);
  };

  useEffect(() => {
    if (!accessToken || !user?.firebaseUserId) {
      return;
    }

    fetchNotifications(1);
    fetchUnreadCount();
  }, [user]);

  useEffect(() => {
    const filtered = notifications.filter(n => {
      if (filter === "all") return true;
      if (filter === "read") return n.read;
      if (filter === "unread") return !n.read;
      return true;
    });
    setFilteredNotifications(filtered);
  }, [notifications, filter]);

  return {
    notifications: filteredNotifications,
    isLoading,
    hasMore,
    unreadCount,
    handleLoadMore,
    sendNotification,
    markAllNotificationsAsRead,
    markNotificationAsRead,
    setFilter: handleFilterChange,
  };
};

export default useNotifications;

import React, { useEffect, useState, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Input,
  Box,
  FormHelperText,
  ModalHeader,
  useToast,
  Textarea,
  Text,
  VStack,
  HStack,
  Divider,
  Stack,
  Grid,
  GridItem,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import { UserState } from "../../../Context/UserProvider";
import { LayoutState } from "../../../Context/LayoutProvider";
import ProjectDropDown from "../Dropdowns/ProjectDropDown";
import PriorityDropDown, { PriorityIcon } from "../Dropdowns/PriorityDropDown";
import AssigneeDropDown from "../Dropdowns/AssigneeDropDown";
import ReporterDropDown from "../Dropdowns/ReporterDropDown";
import MilestoneDropDown from "../Dropdowns/MilestoneDropDown";
import { Trash2Icon, Plus, Minus, X } from "lucide-react";
import ParentTaskSelector from "./ParentSelector";
import { ServiceState } from "../../../Context/ServiceProvider";

const CACHE_KEY = "createTaskModalCache";

const CreateMyTaskModal = ({
  isOpen,
  onClose,
  setAllTasks,
  project,
  processNewTask,
  providedSprintId = null,
  viewingProject,
}) => {
  const { screenWidth } = LayoutState();
  const { selectedOrganization, createTask, fetchAllParentTask } = UserState();
  const { getAssigneeRecommendations } = ServiceState();

  // Add state for display names
  const [title, setTitle] = useState("");
  const [projectId, setProjectId] = useState("");
  const [projectName, setProjectName] = useState(""); // New state for project name
  const [reviewerId, setReviewerId] = useState("");
  const [reviewerName, setReviewerName] = useState(""); // New state for reviewer name
  const [description, setDescription] = useState("");
  const [assigneeId, setAssigneeId] = useState("");
  const [assigneeName, setAssigneeName] = useState(""); // New state for assignee name
  const [dueDate, setDueDate] = useState("");
  const [duration, setDuration] = useState(0);
  const [priority, setPriority] = useState("");
  const [milestone, setMilestone] = useState({ id: "", title: "" });
  const [sprintId, setSprintId] = useState(providedSprintId);
  const [sprintClick, setSprintClick] = useState("yes");
  const [isLoading, setIsLoading] = useState(false);
  const [isProjectDropDownOpen, setIsProjectDropDownOpen] = useState(false);
  const [isPriorityDropDownOpen, setIsPriorityDropDownOpen] = useState(false);
  const [isReporterDropDownOpen, setIsReporterDropDownOpen] = useState(false);
  const [isAssigneeDropDownOpen, setIsAssigneeDropDownOpen] = useState(false);
  const [labels, setLabels] = useState([]);
  const [parentTaskTitle, setParentTaskTitle] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [parentId, setParentId] = useState("");
  const [allParentTasks, setAllParentTasks] = useState([]);
  const [recommendedAssignees, setRecommendedAssignees] = useState([]);
  const [isLoadingRecommendations, setIsLoadingRecommendations] =
    useState(false);
  const latestKeyInputRef = useRef(null);
  const toast = useToast();

  // Modified cache management functions
  const saveToCache = () => {
    const cacheData = {
      title,
      projectId,
      projectName,
      reviewerId,
      reviewerName,
      description,
      assigneeId,
      assigneeName,
      dueDate,
      duration,
      priority,
      milestone,
      labels,
      parentTaskTitle,
      parentId,
    };
    localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
  };

  const loadFromCache = () => {
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const data = JSON.parse(cachedData);
      setTitle(data.title || "");
      setProjectId(data.projectId || "");
      setProjectName(data.projectName || "");
      setReviewerId(data.reviewerId || "");
      setReviewerName(data.reviewerName || "");
      setDescription(data.description || "");
      setAssigneeId(data.assigneeId || "");
      setAssigneeName(data.assigneeName || "");
      setDueDate(data.dueDate || "");
      setDuration(data.duration || 0);
      setPriority(data.priority || "");
      setMilestone(data.milestone || { id: "", title: "" });
      setLabels(data.labels || []);
      setParentTaskTitle(data.parentTaskTitle || "");
      setParentId(data.parentId || "");
    }
  };

  const clearCache = () => {
    localStorage.removeItem(CACHE_KEY);
  };

  const clearAll = () => {
    setTitle("");
    setDescription("");
    setProjectId("");
    setProjectName("");
    setReviewerId("");
    setReviewerName("");
    setAssigneeId("");
    setAssigneeName("");
    setDueDate("");
    setDuration(0);
    setPriority("");
    setMilestone({ id: "", title: "" });
    setLabels([]);
    setParentId("");
    setParentTaskTitle("");
    clearCache();
  };

  // Load cached data when modal opens
  useEffect(() => {
    if (isOpen) {
      loadFromCache();
    }
  }, [isOpen]);

  // Save to cache whenever form fields change
  useEffect(() => {
    if (isOpen) {
      saveToCache();
    }
  }, [
    title,
    projectId,
    projectName,
    reviewerId,
    reviewerName,
    description,
    assigneeId,
    assigneeName,
    dueDate,
    duration,
    priority,
    milestone,
    labels,
    parentTaskTitle,
    parentId,
  ]);
  const priorityStyle = {
    high: "red.500",
    medium: "orange.400",
    low: "green.500",
  };

  const priorityText = {
    high: "High",
    medium: "Medium",
    low: "Low",
  };

  const handleAddCustomField = () => {
    const emptyKeyLabel = labels.find((label) => !label.key);
    if (emptyKeyLabel) {
      return;
    }
    setLabels([...labels, { key: "", value: "" }]);
  };

  const handleCustomFieldChange = (index, newKey, newValue) => {
    const updatedLabels = labels.map((label, i) =>
      i === index ? { key: newKey, value: newValue } : label
    );
    setLabels(updatedLabels);
  };

  const handleRemoveCustomField = (index) => {
    const updatedLabels = labels.filter((_, i) => i !== index);
    setLabels(updatedLabels);
  };

  useEffect(() => {
    if (projectId) {
      fetchAllParentTask({ projectId: projectId })
        .then((tasks) => {
          if (Array.isArray(tasks)) {
            setAllParentTasks(tasks);
          } else {
            console.error("Expected tasks to be an array, but got:", tasks);
            setAllParentTasks([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching tasks:", error);
          setAllParentTasks([]);
        });
    }
  }, [projectId, fetchAllParentTask]);

  useEffect(() => {
    if (parentTaskTitle) {
      const filteredTasks = allParentTasks.filter((t) =>
        t.title.toLowerCase().includes(parentTaskTitle.toLowerCase())
      );
      setSuggestions(filteredTasks);
    } else {
      setSuggestions([]);
    }
  }, [parentTaskTitle, allParentTasks]);

  const modifiedOnClose = () => {
    setTitle("");
    setDescription("");
    setProjectId("");
    setReviewerId("");
    setAssigneeId("");
    setDueDate("");
    setDuration(0);
    setPriority("");
    setMilestone({ id: "", title: "" });
    setIsProjectDropDownOpen(false);
    setLabels([]);
    setParentId("");
    onClose();
  };

  const handleCreateTask = async () => {
    try {
      setIsLoading(true);
      const taskData = {};
      if (projectId) taskData.projectId = projectId;
      if (selectedOrganization.id) taskData.orgId = selectedOrganization.id;
      if (assigneeId) taskData.assigneeId = assigneeId;
      if (title) taskData.title = title;
      if (description) taskData.description = description;
      if (reviewerId) taskData.reviewerId = reviewerId;
      if (dueDate) taskData.dueDate = dueDate;
      if (duration > 0) taskData.duration = duration;
      if (priority) taskData.priority = priority;
      if (milestone.id) taskData.milestone = milestone;
      if (sprintId) taskData.sprintId = sprintId;
      if (labels.length > 0) {
        taskData.labels = labels.reduce((acc, { key, value }) => {
          if (key) {
            acc[key] = value;
          }
          return acc;
        }, {});
      }
      if (parentId) taskData.parentId = parentId;

      console.log("consoling task data from create task", taskData);

      const newTask = await createTask(
        taskData,
        project ? true : false,
        setAllTasks
      );

      if (project && !setAllTasks) {
        processNewTask(newTask);
      }

      toast({
        title: "Task created successfully.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      toast({
        title: "Task creation failed.",
        description: error.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDurationChange = (e) => {
    let value = e.target.value;
    value = value.replace(/^0+(?=\d+(\.\d+)?)/, "");
    value = value.replace(/\.+/g, ".");
    value = value.replace(/[^\d.]/g, "");
    if (value === "" || value === ".") {
      value = "0";
    }
    setDuration(Number.parseFloat(value));
  };

  const handleDueDateChange = (e) => {
    const selectedDate = e.target.value;
    setDueDate(selectedDate);
  };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const recommendAssignees = async () => {
    try {
      setIsLoadingRecommendations(true);
      setIsAssigneeDropDownOpen(true);
      const assignees = await getAssigneeRecommendations(
        projectId,
        title,
        description
      );

      setRecommendedAssignees(assignees);
      console.log("Recommended Assignees:", assignees);
    } catch (error) {
      console.log("Error in recommendAssignees:", error);
    } finally {
      setIsLoadingRecommendations(false);
    }
  };

  const clearRecommendations = () => {
    setRecommendedAssignees([]);
  };

  useEffect(() => {
    if (providedSprintId) {
      setSprintId(providedSprintId);
    } else {
      if (sprintClick === "yes") {
        setSprintId(
          project?.sprints?.activeSprint || project?.sprints?.backlogSprint
        );
      } else {
        setSprintId(project?.sprints?.backlogSprint);
      }
    }
  }, []);

  useEffect(() => {
    if (isOpen && !selectedOrganization) {
      toast({
        title: "Cannot create task",
        description: `An organization must be selected before creating a new task`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      onClose();
    }
  }, [isOpen]);

  useEffect(() => {
    if (latestKeyInputRef.current) {
      latestKeyInputRef.current.focus();
    }
  }, [labels.length]);

  const IconBox = ({ icon: Icon, onClick, label }) => (
    <Box
      as={Icon}
      size="20px"
      cursor="pointer"
      _hover={{ color: "gray.700", bg: "gray.200", transform: "scale(1.1)" }}
      transition="all 0.2s"
      borderRadius="full"
      onClick={onClick}
      aria-label={label}
    />
  );

  return (
    <Modal isOpen={isOpen} onClose={modifiedOnClose} isCentered size="4xl">
      <ModalOverlay />
      <ModalContent
        maxHeight="90vh"
        overflow="auto"
        borderRadius="lg"
        bg="white"
        boxShadow="xl"
        maxWidth={`${Math.min(0.8 * screenWidth, 900)}px`}
      >
        <ModalHeader
          color="black"
          borderTopLeftRadius="lg"
          borderTopRightRadius="lg"
          pb={0}
        >
          <FormControl isRequired>
            <Input
              value={title}
              className="my-task-create-title"
              placeholder="Task Title"
              onChange={(e) => setTitle(e.target.value)}
              size="lg"
              border="none"
              fontSize="2xl"
              fontWeight="bold"
              _placeholder={{ color: "black.200" }}
              _focus={{ boxShadow: "none" }}
              autoFocus={false}
            />
          </FormControl>
          <Box
            position="absolute"
            top="12px"
            right="12px"
            display="flex"
            alignItems="center"
            gap={3}
            zIndex={1}
          >
            <Button
              onClick={clearAll}
              colorScheme="red"
              borderRadius="full"
              padding="2"
              fontWeight="bold"
              size="sm"
              _hover={{ bg: "red.300" }}
              _focus={{ boxShadow: "outline" }}
            >
              Clear All
            </Button>

            <IconBox
              icon={X}
              onClick={modifiedOnClose}
              label="Close modal"
              bg="gray.100"
              borderRadius="full"
              p="1"
              _hover={{ bg: "gray.200" }}
            />
          </Box>
        </ModalHeader>
        <ModalBody pt={1}>
          <VStack spacing={6} align="stretch" pb={4}>
            <Box px={6} borderBottom="1px" borderColor="gray.200" pb={2}>
              <Text fontSize="md" fontWeight="medium" color="gray.600" mb={1}>
                Labels
              </Text>
              <Grid templateColumns="1fr 1fr" gap={2}>
                {labels.map((label, index) => (
                  <React.Fragment key={index}>
                    <GridItem>
                      <HStack spacing={2} role="group" alignItems="center">
                        <Box
                          as="button"
                          aria-label="Delete label"
                          opacity={0}
                          _groupHover={{ opacity: 1 }}
                          onClick={() => handleRemoveCustomField(index)}
                          color="gray.500"
                          fontSize="sm"
                          display="flex"
                          alignItems="center"
                          cursor="pointer"
                        >
                          <Minus size={12} />
                        </Box>
                        <Input
                          ref={
                            index === labels.length - 1
                              ? latestKeyInputRef
                              : null
                          }
                          placeholder="Key"
                          value={label.key}
                          onChange={(e) =>
                            handleCustomFieldChange(
                              index,
                              e.target.value,
                              label.value
                            )
                          }
                          size="sm"
                          variant="unstyled"
                          fontSize="sm"
                          color="gray.600"
                        />
                      </HStack>
                    </GridItem>
                    <GridItem>
                      <Input
                        placeholder="Value"
                        value={label.value}
                        onChange={(e) =>
                          handleCustomFieldChange(
                            index,
                            label.key,
                            e.target.value
                          )
                        }
                        size="sm"
                        variant="unstyled"
                        fontSize="sm"
                      />
                    </GridItem>
                  </React.Fragment>
                ))}
                <GridItem colSpan={2}>
                  <Button
                    leftIcon={<Plus size={12} />}
                    variant="ghost"
                    size="sm"
                    color="gray.500"
                    fontWeight="normal"
                    onClick={handleAddCustomField}
                    _hover={{ bg: "gray.50" }}
                    mt={2}
                  >
                    Add a label
                  </Button>
                </GridItem>
              </Grid>
            </Box>

            <Stack
              direction={screenWidth < 950 ? "column" : "row"}
              spacing={4}
              wrap="wrap"
            >
              <FormControl isRequired flex={1} minW="200px">
                <ProjectDropDown
                  onClose={() => setIsProjectDropDownOpen(false)}
                  onOpen={() => setIsProjectDropDownOpen(true)}
                  isOpen={isProjectDropDownOpen}
                  setProjectId={(id, name) => {
                    setProjectId(id);
                    setProjectName(name); // Save project name when selected
                  }}
                  project={project}
                  initialValue={projectName}
                  value={projectName} // Pass cached name as initial value
                />
                <FormHelperText>Project</FormHelperText>
              </FormControl>

              <Stack flex={2} direction={screenWidth < 570 ? "column" : "row"}>
                <FormControl isRequired flex={1} minW="200px">
                  <AssigneeDropDown
                    onClose={() => setIsAssigneeDropDownOpen(false)}
                    onOpen={() => setIsAssigneeDropDownOpen(true)}
                    isOpen={isAssigneeDropDownOpen}
                    setAssigneeId={(id, name) => {
                      setAssigneeId(id);
                      setAssigneeName(name); // Save assignee name when selected
                    }}
                    projectId={projectId}
                    reviewerId={reviewerId}
                    recommendedAssignees={recommendedAssignees}
                    isLoadingRecommendations={isLoadingRecommendations}
                    clearRecommendations={clearRecommendations}
                    initialValue={assigneeName}
                    value={assigneeName} // Pass cached name as initial value
                  />
                  <FormHelperText>Assignee</FormHelperText>
                </FormControl>
                <FormControl isRequired flex={1} minW="200px">
                  <ReporterDropDown
                    onClose={() => setIsReporterDropDownOpen(false)}
                    onOpen={() => setIsReporterDropDownOpen(true)}
                    isOpen={isReporterDropDownOpen}
                    setReviewerId={(id, name) => {
                      setReviewerId(id);
                      setReviewerName(name); // Save reviewer name when selected
                    }}
                    projectId={projectId}
                    assigneeId={assigneeId}
                    initialValue={reviewerName} // Pass cached name as initial value
                    value={reviewerName}
                  />
                  <FormHelperText>Reviewer</FormHelperText>
                </FormControl>
              </Stack>
            </Stack>

            <ParentTaskSelector
              projectId={projectId}
              suggestions={suggestions}
              setSuggestions={setSuggestions}
              fetchSuggestions={fetchAllParentTask}
              parentTaskTitle={parentTaskTitle}
              setParentTaskTitle={setParentTaskTitle}
              setParentId={setParentId}
            />

            <FormControl isRequired>
              <Textarea
                value={description}
                placeholder="Task Description"
                onChange={(e) => setDescription(e.target.value)}
                minH="200px"
                resize="vertical"
                bgColor={"gray.100"}
                borderColor="gray.300"
                _hover={{ borderColor: "gray.400" }}
                _focus={{ borderColor: "blue.500", boxShadow: "outline" }}
              />
            </FormControl>

            <Divider />

            <HStack spacing={4} wrap="wrap">
              <FormControl flex={1} minW="150px">
                <Input
                  value={duration}
                  bgColor={"gray.100"}
                  min={0}
                  placeholder="Duration"
                  onChange={handleDurationChange}
                  type="text"
                  inputMode="decimal"
                  step="0.1"
                />
                <FormHelperText>Duration (in hours)</FormHelperText>
              </FormControl>

              <FormControl flex={1} minW="150px">
                <Input
                  value={dueDate}
                  bgColor="gray.100"
                  placeholder="Select Due Date"
                  onChange={handleDueDateChange}
                  type="date"
                  min={getTodayDate()}
                />
                <FormHelperText>Due Date</FormHelperText>
              </FormControl>
            </HStack>

            <HStack spacing={4} wrap="wrap">
              {viewingProject?.management === "agile" && (
                <FormControl flex={1} minW="150px">
                  <MilestoneDropDown
                    projectId={projectId}
                    milestone={milestone}
                    setMilestone={setMilestone}
                  />
                  <FormHelperText>Milestone</FormHelperText>
                </FormControl>
              )}

              <FormControl flex={1} minW="150px">
                <PriorityDropDown
                  onClose={() => setIsPriorityDropDownOpen(false)}
                  onOpen={() => setIsPriorityDropDownOpen(true)}
                  isOpen={isPriorityDropDownOpen}
                  priority={priority}
                  setPriority={setPriority}
                >
                  <Button
                    w="full"
                    bg={"gray.100"}
                    color={priority ? "black" : "gray.800"}
                    _hover={{ opacity: 0.8 }}
                    fontWeight={"400"}
                    textAlign={"left"}
                  >
                    <Box>
                      {priority ? (
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <PriorityIcon priority={priority} />
                          <Text color={priorityStyle[priority]}>
                            {priorityText[priority]}
                          </Text>
                        </Box>
                      ) : (
                        <Text color={"gray.500"}>Select Priority</Text>
                      )}
                    </Box>
                  </Button>
                </PriorityDropDown>
                <FormHelperText>Priority</FormHelperText>
              </FormControl>
            </HStack>

            <Button
              isLoading={isLoading}
              loadingText="Creating Task"
              color={"gray.500"}
              bgColor={"blue.100"}
              size="lg"
              _hover={{ bgColor: "blue.400", color: "white" }}
              onClick={handleCreateTask}
            >
              Create Task
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateMyTaskModal;
